import {
  GetTaxOperationsParams,
    ICursorPaginationResponse,
    ITaxOperation,
    TTaxOperationsSummary,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE } from '@dltru/dfa-ui'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
  isLoading: boolean
  items: Partial<ICursorPaginationResponse<ITaxOperation>>
  error: string | null
  params: GetTaxOperationsParams

  summary: TTaxOperationsSummary | null
}

const initialState: IInitialState = {
  isLoading: false,
  items: {},
  params: { limit: DEFAULT_PER_PAGE },
  error: null,

  summary: null,
}

export const taxOperationsSlice = createSlice({
    name: 'taxOperations',
    initialState,
    reducers: {
      setIsLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload
      },
      setError: (state, action: PayloadAction<string>) => {
        state.error = action.payload
      },
      setParams: (state, action: PayloadAction<GetTaxOperationsParams>) => {
        state.params = { ...state.params, ...action.payload }
      },
      setItems: (state, action: PayloadAction<ICursorPaginationResponse<ITaxOperation>>) => {
        state.items = action.payload
      },
      setSummary: (state, action: PayloadAction<TTaxOperationsSummary>) => {
        state.summary = action.payload
      },
      clearAll: (state) => {
        state.isLoading = false
        state.items = {}
        state.params = initialState.params
        state.error = null
        state.summary = null
      },
    },
})

export const getOperations = createAction('taxOperations/getOperations')
export const getOperationsSummary = createAction('taxOperations/getOperationsSummary')

export default taxOperationsSlice.reducer
