import { AppState } from '@store/index'

const selectItems = (state: AppState) => state.transactionDetails.items
const selectIsLoading = (state: AppState) => state.transactionDetails.isLoading
const selectError = (state: AppState) => state.transactionDetails.error

export const transactionDetailsSelectors = {
    selectItems,
    selectIsLoading,
    selectError,
}
