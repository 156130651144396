import { TariffPlaneEnum } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { tariffSelector } from '@store/tariff/selectors'

import api from '@services/api'

import { tariffSlice } from './index'

function* getBaseTariff() {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))
        const baseTariff = yield* select(tariffSelector.selectBaseTariff)
        let baseTariffId = baseTariff?.id
        if (!baseTariff?.id) {
            const { data, error } = yield* call(api.lib.getTariffPlane, {
                tariff_type: TariffPlaneEnum.BASE,
            })
            if (error || data?.error) {
                throw error ?? data?.error
            }
            baseTariffId = data.items[0].id
        }

        if (baseTariffId) {
            const { data: fullData, error: fullDataError } = yield* call(api.lib.getTariffPlane, {
                tariff_id: baseTariffId,
            })
            if (fullDataError || fullData?.error) {
                throw fullDataError ?? fullData?.error
            }
            yield* put(tariffSlice.actions.setBaseTariff(fullData?.items?.[0]))
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении базового тарифного плана',
        })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* getFees() {
    try {
        const fees = yield* select(tariffSelector.selectFees)
        if (!fees?.length) {
            yield* put(tariffSlice.actions.setIsLoading(true))

            const { data, error } = yield* call(api.lib.getAllFees)
            if (error || data?.error) {
                throw error ?? data?.error
            }
            if (data?.items?.length) {
                yield* put(tariffSlice.actions.setFees(data.items))
            }
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении комиссий',
        })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* getCurrentTariffByUserUuid({
    payload,
}: ReturnType<typeof tariffSlice.actions.getCurrentTariffByUserUuid>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getTariffByUser, { user_id: payload })
        if (error || data?.error) {
            throw error ?? data?.error
        }

        const { data: logs, error: logsError } = yield* call(api.lib.getTariffAssignmentLog, {
            user_id: payload,
        })

        if (logsError || logs?.error) {
            throw logsError ?? logs?.error
        }

        if (data.tariff && logs?.items) {
            const activeLog = logs.items.find((log) => {
                return log.tariff_id === data.tariff.active_grid.tariff_id
            })
            if (activeLog) {
                // подменим дату активации тарифа на активацию для пользователя/выпуска
                data.tariff.active_grid.activated_at = activeLog.created_at
            }
            yield* put(tariffSlice.actions.setCurrentTariff(data.tariff))
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при получении тарифного плана' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* getCurrentTariffByDfaId({
    payload,
}: ReturnType<typeof tariffSlice.actions.getCurrentTariffByDfaId>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getTariffByAsset, { asset_id: payload })
        if (error || data?.error) {
            throw error ?? data?.error
        }

        const { data: logs, error: logsError } = yield* call(api.lib.getTariffAssignmentLog, {
            asset_id: payload,
        })

        if (logsError || logs?.error) {
            throw logsError ?? logs?.error
        }

        if (data.tariff && logs?.items) {
            const activeLog = logs.items.find(
                (log) => log.tariff_id === data.tariff.active_grid.tariff_id,
            )
            if (activeLog) {
                // подменим дату активации тарифа на активацию для пользователя/выпуска
                data.tariff.active_grid.activated_at = activeLog.created_at
            }

            yield* put(tariffSlice.actions.setCurrentTariff(data.tariff))
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при получении тарифного плана' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

export function* watchTariff(): Generator<StrictEffect> {
    yield* takeLatest(tariffSlice.actions.getBaseTariff.type, getBaseTariff)
    yield* takeLatest(tariffSlice.actions.getFees.type, getFees)
    yield* takeLatest(
        tariffSlice.actions.getCurrentTariffByUserUuid.type,
        getCurrentTariffByUserUuid,
    )
    yield* takeLatest(tariffSlice.actions.getCurrentTariffByDfaId, getCurrentTariffByDfaId)
}
