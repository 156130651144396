/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { FeeType, ITariffPlane } from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    error: string
    currentTariff: ITariffPlane | null
    baseTariff: ITariffPlane | null
    fees: FeeType[]
}
const initialState: IInitialState = {
    isLoading: false,
    error: '',
    baseTariff: null,
    currentTariff: null,
    fees: [],
}

export const tariffSlice = createSlice({
    name: 'tariff',
    initialState,
    reducers: {
        setCurrentTariff: (state, action: PayloadAction<ITariffPlane>) => {
            state.currentTariff = action.payload
        },
        setFees: (state, action: PayloadAction<FeeType[]>) => {
            state.fees = action.payload
        },
        setBaseTariff: (state, action: PayloadAction<ITariffPlane>) => {
            state.baseTariff = action.payload
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        getFees: () => {},
        getBaseTariff: () => {},
        getCurrentTariffByUserUuid: (_, action: PayloadAction<string>) => {},
        getCurrentTariffByDfaId: (_, action: PayloadAction<string | number>) => {},
        clearItem: (state) => {
            state.error = ''
            state.isLoading = false
            state.currentTariff = null
        },
    },
})

export default tariffSlice.reducer
