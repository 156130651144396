import {
    GetTaxesParams,
    ICursorPaginationResponse,
    TTax,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE } from '@dltru/dfa-ui'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
  isLoading: boolean
  items: Partial<ICursorPaginationResponse<TTax>>
  error: string | null
  params: GetTaxesParams
}

const initialState: IInitialState = {
  isLoading: false,
  items: {},
  params: { limit: DEFAULT_PER_PAGE },
  error: null,
}

export const taxTaxesSlice = createSlice({
    name: 'taxTaxes',
    initialState,
    reducers: {
      setIsLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload
      },
      setError: (state, action: PayloadAction<string>) => {
        state.error = action.payload
      },
      setParams: (state, action: PayloadAction<GetTaxesParams>) => {
        state.params = { ...state.params, ...action.payload }
      },
      setItems: (state, action: PayloadAction<ICursorPaginationResponse<TTax>>) => {
        state.items = action.payload
      },
      clearAll: (state) => {
        state.isLoading = false
        state.items = {}
        state.params = initialState.params
        state.error = null
      }
    },
})

export const getTaxes = createAction('taxTaxes/getTaxes')

export default taxTaxesSlice.reducer
