import { createSelector } from '@reduxjs/toolkit'
import IAppState from '../../states'

const selectIsLoading = (state: IAppState) => state.taxTaxes.isLoading

const selectItems = (state: IAppState) => state.taxTaxes.items.items
const selectPaging = (state: IAppState) => state.taxTaxes.items?.paging
const selectParams = (state: IAppState) => state.taxTaxes.params

const selectTotalTaxAmount = createSelector(
  selectItems,
  (items) => items?.reduce((acc, cur) => acc += cur.tax_amount, 0) || 0
)

const selectTotalTaxWithholding = createSelector(
  selectItems,
  (items) => items?.reduce((acc, cur) => acc += cur.tax_withholding, 0) || 0
)

const selectTaxAmountWithoutWithholding = createSelector(
  selectTotalTaxAmount,
  selectTotalTaxWithholding,
  (taxAmount, taxWithholding) => Math.max(taxAmount - taxWithholding, 0)
)

export const taxTaxesSelector = {
    selectItems,
    selectPaging,
    selectIsLoading,
    selectParams,
    selectTotalTaxAmount,
    selectTotalTaxWithholding,
    selectTaxAmountWithoutWithholding,
}
