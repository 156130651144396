import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import api from '@services/api'
import { openMessage } from '@dltru/dfa-ui'
import qs from 'qs'
import { taxTaxesSelector } from './selectors'
import { getTaxes, taxTaxesSlice } from '.'

function* getItemsHandler() {
  try {
    yield* put(taxTaxesSlice.actions.setIsLoading(true))
    const params = yield* select(taxTaxesSelector.selectParams)
    const payloadStringify = qs.stringify(params, { arrayFormat: 'repeat', encode: false })
    const queryString = `?${payloadStringify}`

    const { data, error } = yield* call(api.lib.getTaxTaxes, queryString)
    if (error || data?.error) {
      throw error ?? data?.error
    }

    if (data?.items) {
      yield* put(taxTaxesSlice.actions.setItems(data))
    }
  } catch (error) {
    openMessage({
      type: 'error',
      message: 'Возникли ошибки при получении налоговых данных',
    })
  } finally {
    yield* put(taxTaxesSlice.actions.setIsLoading(false))
  }
}

export function* watchTaxTaxesSlice(): Generator<StrictEffect> {
  yield* takeLatest(getTaxes.type, getItemsHandler)
}
