import IAppState from '../../states'

const selectIsLoading = (state: IAppState) => state.taxOperations.isLoading

const selectItems = (state: IAppState) => state.taxOperations.items.items
const selectPaging = (state: IAppState) => state.taxOperations.items?.paging
const selectParams = (state: IAppState) => state.taxOperations.params

const selectSummary = (state: IAppState) => state.taxOperations.summary

export const taxOperationsSelector = {
    selectIsLoading,
    selectItems,
    selectParams,
    selectPaging,

    selectSummary
}
