export type Paging = {
    cursors?: {
        next?: string
        prev?: string
    }
}

export enum OrderDirect {
    asc = 'asc',
    desc = 'desc',
}

export type TableOrder = {
    direct: OrderDirect
    column: string
}

export type CursorPaginationParam = {
    cursor?: string
    order?: TableOrder
    limit?: number | string
    process_name?: string
    executor?: string
}
