/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    items: any // TODO CReATE TYPE
    error: string | null
    isLoading: boolean
}
const initialState: IInitialState = {
    items: null,
    error: null,
    isLoading: false,
}
export const transactionDetailsSlice = createSlice({
    name: 'transactionDetailsSlice',
    initialState,
    reducers: {
        setIsLoadingTransactionDetails(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<IInitialState['error']>) {
            state.error = action.payload ?? null
        },
        setItemsTransactionDetails(state, action: PayloadAction<Pick<IInitialState, 'items'>>) {
            state.items = action.payload
        },
        clearDataTransactionDetails: (state) => {
            state.items = null
            state.error = null
            state.isLoading = false
        },
    },
})

export const { setItemsTransactionDetails, clearDataTransactionDetails, setIsLoadingTransactionDetails } =
    transactionDetailsSlice.actions

export default transactionDetailsSlice.reducer
