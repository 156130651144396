import { FeeType, IRateBaseFront, IRateBase, rateBackToFront } from '@dltru/dfa-models'
import { createSelector } from 'reselect'

import IAppState from '../states'

const selectCurrentTariff = (state: IAppState) => state.tariff.currentTariff
const selectBaseTariff = (state: IAppState) => state.tariff.baseTariff
const selectBaseRates = createSelector(selectBaseTariff, (baseTariff) =>
    baseTariff?.active_grid?.rates?.length
        ? baseTariff.active_grid.rates.reduce(
              (acc: Record<string, IRateBaseFront>, rate: IRateBase) => {
                  acc[rate.fee_code] = rateBackToFront(rate)
                  return acc
              },
              {},
          )
        : ({} as Record<string, IRateBase>),
)
const selectIsLoading = (state: IAppState) => state.tariff.isLoading
const selectFees = (state: IAppState) => state.tariff.fees
const selectFeeNames = createSelector(selectFees, (fees) =>
    fees?.length
        ? fees.reduce((acc: Record<string, string>, fee: FeeType) => {
              acc[fee.code] = fee.name
              return acc
          }, {})
        : ({} as Record<string, string>),
)

export const tariffSelector = {
    selectIsLoading,
    selectBaseTariff,
    selectFees,
    selectFeeNames,
    selectBaseRates,
    selectCurrentTariff,
}
