import { createSlice } from '@reduxjs/toolkit'

export interface IUIState {
    clientCurrentTab: null | string
}

const initialState = {
    clientCurrentTab: null,
} as IUIState

const authSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        getUIState: (state) => {
            return state
        },
        setUIState: (state, action) => {
            return { ...state, ...action.payload }
        },
    },
})

export const { getUIState, setUIState } = authSlice.actions

export default authSlice.reducer
